import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Image from '../components/image'
import { graphql } from 'gatsby'

import styles from './promos.module.css'
import PageLinks from '../components/page-links'
import Modal from '../components/modal'
import SEO from '../components/seo'

const PromotionCard = ({ promotion }) => {
  const {
    descriptionHtml,
    conditions,
    name,
    shortDescription,
    cardImage,
    modalImage,
    validity,
  } = promotion

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
    <SEO 
      title={"Current drink and food promotions at The Intermission Bar"}
      description={"We are giving you good reasons to come to Golden Mile Tower for some cocktails, beer and food with happy hour promotions and one-for-one deals."}
    />
      <div className={`${styles.promoWrapper} flex flex-col`}>
        <div onClick={() => setIsModalOpen(true)}>
          <Image
            className={`${styles.promoImage}`}
            fluid={cardImage && cardImage.childImageSharp.fluid}
          />
        </div>
        <div className={`${styles.promoDetailsContainer} flex flex-col flex-1`}>
          <div className="flex-1">
            <h1 className={styles.promoName}>{name}</h1>
            {shortDescription ? (
              <p className={styles.typeDescription}>{shortDescription}</p>
            ) : (
              ''
            )}
          </div>
          <div
            className="inline-block flex-initial"
            onClick={() => setIsModalOpen(true)}
          >
            <button className={`${styles.promoButton} btn-primary`}>
              More Info
            </button>
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        className={styles.promoModal}
      >
        <div className="w-full h-full text-left">
          <Image
            className={`${styles.promoModalImage}`}
            fluid={modalImage && modalImage.childImageSharp.fluid}
          />
          <div className={styles.promoModalContent}>
            <h1 className={styles.promoModalName}>{name}</h1>
            <div className={styles.promoModalValidity}>
              Validity: {validity}
            </div>
            <div
              className={`${styles.promoModalDescription}`}
              dangerouslySetInnerHTML={{ __html: descriptionHtml }}
            />
            {(conditions || []).length ? (
              <>
                <div className={`${styles.promoModalHeader}`}>Conditions</div>
                <div className={`${styles.promoConditionsList}`}>
                  {conditions.map((c, i) => (
                    <div key={i} className={`${styles.promoCondition}`}>
                      {c}
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  )
}

export const PromosTemplate = ({ page }) => {
  const {
    html,
    frontmatter: { title, subtitle, promotions, pageLinks },
  } = page

  return (
    <div className="container flex flex-col text-center items-center py-5 lg:py-20">
      <div className="w-full md:w-4/5 lg:w-3/5">
        {subtitle ? <h2 className={styles.promosSubtitle}>{subtitle}</h2> : ''}
        <h1 className={styles.promosTitle}>{title}</h1>
        {html ? (
          <div
            className={`${styles.promosBody} mx-auto`}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        ) : (
          ''
        )}
      </div>
      <div
        className={`${styles.promosWrapper} flex flex-wrap justify-left items-stretch my-20`}
      >
        {promotions.map((g, i) => (
          <div className="w-full mx-0 p-2 md:w-1/2 lg:w-1/3" key={i}>
            <PromotionCard promotion={g} />
          </div>
        ))}
      </div>
      <div className="w-full mt-24">
        <PageLinks links={pageLinks} />
      </div>
    </div>
  )
}

const PromosPage = ({ data }) => {
  return <PromosTemplate {...data} />
}

PromosPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export default PromosPage

export const pageQuery = graphql`
  query PromosPage {
    page: markdownRemark(fields: { slug: { eq: "/promos/" } }) {
      frontmatter {
        title
        subtitle
        promotions {
          descriptionHtml
          conditions
          name
          validity
          shortDescription
          cardImage: image {
            childImageSharp {
              fluid(maxWidth: 390, maxHeight: 330, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          modalImage: image {
            childImageSharp {
              fluid(maxWidth: 780, maxHeight: 660, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ...PageLinks
      }
      html
    }
  }
`
